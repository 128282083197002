exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-es-js": () => import("./../../../src/pages/es.js" /* webpackChunkName: "component---src-pages-es-js" */),
  "component---src-pages-fr-js": () => import("./../../../src/pages/fr.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-gl-js": () => import("./../../../src/pages/gl.js" /* webpackChunkName: "component---src-pages-gl-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jp-js": () => import("./../../../src/pages/jp.js" /* webpackChunkName: "component---src-pages-jp-js" */),
  "component---src-pages-pt-js": () => import("./../../../src/pages/pt.js" /* webpackChunkName: "component---src-pages-pt-js" */)
}

